import { render, staticRenderFns } from "./Inventory.vue?vue&type=template&id=375360b3&scoped=true&"
import script from "./Inventory.vue?vue&type=script&lang=js&"
export * from "./Inventory.vue?vue&type=script&lang=js&"
import style0 from "./Inventory.vue?vue&type=style&index=0&id=375360b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375360b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardTitle,VContainer,VSheet,VTab,VTabItem,VTabs})
