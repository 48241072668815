<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          :loading="loading"
          :items-per-page="pages"
          loading-text="Cargando..."

        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                <v-icon>cached</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.type_product="{ item }">
            <div v-for="type in typesProducts" :key="type.value">
              <div v-if="item.type_product == type.value">
                {{ type.text }}
              </div>
            </div>
          </template>
          <template v-slot:no-data>
            No hay productos dentro del inventario
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { ApiInv } from "./ApiInv";

export default {
    name: "ListProducts",
    data() {
      return{
      items: [],
      categorias:[],
      api: new ApiInv(),
      loading: false,
      search: "",
      pages:-1,
      headers: [
        {text: "ID", value: "id" },
        {text:'Producto',value:'product_description',sortable:false},
        {text:'Categoria',value:'cat_description',sortable:false},
        {text:'Tipo de producto',value:'type_product' ,sortable:true},
        {text:'Existencia',value:'product_existence' ,sortable:false},
      
      ],
      typesProducts:[
        {text:"VENTA", value:"VT"},
        {text:"CONSUMO", value:"CN"}
      ],
      }
    },
    methods: {
      async iniciar() {
        try {
          this.loading = true;
          let params=`enterprise=${localStorage.getItem("SucursalId")}`
          let r = await this.api.getInventarioP(params);
          this.items = r;
          this.categorias = await this.api.getCategorias() 
        } catch (error) {
          alert(error);
          // this.mensaje(error,"Error","error")
          this.msgError(error)
        } finally {
          this.loading = false;
        }
    },
  },
  created() {
      this.iniciar();
  },
}
</script>
<style scoped>

</style>