<template>
  <div class="pl-14">
    <v-card
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      absolute
      dark
      scroll-target="#scrolling-techniques-6"
      elevate-on-scroll
    >
      <v-card-title>
        <h1 class="font-weight-bold display-6 color-texto">Inventario {{sucursal}}</h1>
      </v-card-title>
    </v-card>

    <v-tabs 
      grow
      dark
    >
      <v-tab>Inventario</v-tab>
      <v-tab>Categorias</v-tab>
      <v-tab>Productos</v-tab>
      <v-tab>Servicios</v-tab>
      <v-tab>Ingresos</v-tab>
      <v-tab>Remisiones</v-tab>
      <v-tab-item><br><ListProducts /></v-tab-item>
      <v-tab-item><br><Categoria /></v-tab-item>
      <v-tab-item><br><Producto /></v-tab-item>
      <v-tab-item><br><Services /></v-tab-item>
      <v-tab-item><br><Ingress /></v-tab-item>
      <v-tab-item><br><Remissions /></v-tab-item>
    </v-tabs>
    <v-sheet id="scrolling-techniques-6" class="overflow-y-auto" max-height="600">
      <v-container style="height: 60px;"></v-container>
    </v-sheet>
  </div>
</template>

<script>
import ListProducts from "./ListProducts"
import Categoria from "./Category"
import Producto from "./Product"
import Services from "./Services"
import Ingress from "./Ingress"
import Remissions from "./Remissions"

export default {
  name: "Inventario",
  components: {
    ListProducts,
    Categoria,
    Producto,
    Services,
    Ingress,
    Remissions,
  },
  data() {
    return {
        opt: null,
        collapseOnScroll: true,
        sucursal: localStorage.getItem("Sucursal"),
    };
  },
  methods: {
    chooseOpt(opt){
      this.opt = opt
    }
  }
};
</script>

<style scoped>
.color-texto {
    color: #356859 !important;
}
</style>
