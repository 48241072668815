<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          :loading="loading"
          :items-per-page="pages"
          loading-text="Cargando..."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Categorías</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field 
                v-model="search" 
                append-icon="mdi-magnify" 
                label="Buscar"
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                <v-icon>cached</v-icon>
              </v-btn>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon>add_box</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="2" sm="2" md="2">
                          <v-text-field v-model="editedItem.id" label="ID" disabled></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" md="3">
                          <v-text-field 
                            v-model="editedItem.code" 
                            label="Codigo"
                            :rules="rules.in_req"
                            color="purple darken-2"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="7" sm="7" md="7">
                          <v-text-field 
                            v-model="editedItem.description" 
                            label="Descripción"
                            :rules="rules.in_req"
                            color="purple darken-2"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { ApiInv } from "./ApiInv";
// import mensajesMixin from "../../../mixins/mensajesMixin"

export default {
  name: "Categoria",
//   mixins:[mensajesMixin],
  data() {
    return {
      items: [],
      api: new ApiInv(),
      loading: false,
      search: "",
      pages:-1,
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Codigo",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "description",
        },
        {text:'Acciones',value:'actions',sortable:false}
      ],
      dialog:false,
      editedIdex:-1,
      editedItem:{
          id:-1,
          code:"",
          description:""
      },
      defaultItem:{
          id:-1,
          code:"",
          description:""
      },
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      }
    };
  },
  computed:{
      formTitle(){
          return (this.editedIdex === -1 ? "Nueva": "Editar") + " Categoría"
      },
      formIsValid () {
        return (
          this.editedItem.code &&
          this.editedItem.description
        )
      },
  },
  watch:{
      dialog(val){
          val || this.close()
      }
  },
  methods: {
    async iniciar() {
      try {
        this.loading = true;
        let r = await this.api.getCategorias();
        this.items = r;
      } catch (error) {
        // alert(error);
        // this.mensaje(error,"Error","error")
        // this.msgError(error)
        this.$swal("Error", error.toString())
      } finally {
        this.loading = false;
      }
    },
    close(){
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    },
    editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
    },
    async save(){
        const obj = this.editedItem
        try {
            this.loading = true
            let r = await this.api.saveCategorias(obj)
            if (!r.ok){
              this.$swal("Error", Object.values(r)[0][0],"error")
            }
            else{
              this.$swal("Guardada","Categoría Guardado Satisfactoriamente", "success")
              this.close()
              this.iniciar()
            }
        } catch (error) {
            // alert(error)
            this.mensaje(error,"Error","error")
        } finally{
            this.loading = false
        }
    },
    async deleteItem(item){
        // if(confirm('¿Borrar Categoría?'))
        // // if(await this.mensajeSiNo(`¿Borrar Categoría ${item.descripcion}?`))
        // {
        //     await this.api.delCategorias(item.id)
        //     this.iniciar()
        //     // this.mensaje("Eliminada Satisfactoriamente","Categoría")
      this.$swal({
        title: '¿Está Seguro?',
        html: `Borrar Categoria <br> <b>${item.description} </b>`,
        type: 'warning',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Borrarlo!',
        cancelButtonText: 'No, Mantenerlo!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(async(result) => {
        if(result.value){
          let r = await this.api.delCategorias(item.id)
          if (r.status === 500){
            this.$swal("Error","No se puede borrar la categoría","error")
          }else{
            this.$swal("Borrado", "Se borro satisfactoriamente la categoría", "success")
            this.iniciar()
          }
        }
      })
    }
  },
  created() {
    this.iniciar();
  },
};
</script>

<style scoped>
</style>