<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          :loading="loading"
          :items-per-page="pages"
          :sort-by="['id','pop_date']"
          update: sort-desc
          loading-text="Cargando..."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Remisiones</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                <v-icon>cached</v-icon>
              </v-btn>

              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="editedItem.pop_cause"
                            :items="types"
                            label="Tipo de remisión"
                            item-text="text"
                            item-value="value"
                            color="purple darken-2"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field 
                            v-model="editedItem.bill" 
                            label="Número de Factura"
                            :rules="rules.in_req"
                            color="purple darken-2"
                          ></v-text-field>
                        </v-col>
                         <v-col cols="4">
                          <v-autocomplete
                            v-if="editedItem.pop_cause.text == 'USO' "
                            v-model="model"
                            :items="itemsEmp"
                            label="Empleado"
                            item-text="name"
                            item-value="identification"
                            color="purple darken-2"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-data-table
                      :headers="product_headers"
                      :items="products"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                        >
                          <v-toolbar-title>Productos</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="push_product"
                          >
                            Añadir Producto
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:body.prepend>
                        <tr>
                          <td>
                            <v-text-field 
                              v-model="editedProduct.item"
                              label="Item"
                              disabled=""
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field 
                              v-model="editedProduct.quantity"
                              label="Cantidad"
                              :rules="rules.in_req"
                              color="purple darken-2"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-autocomplete 
                              v-model="editedProduct.type_product"
                              :items="typesProducts"
                              label="Tipo de Producto"
                              item-text="text"
                              item-value="value"
                              color="purple darken-2"
                              return-object
                            ></v-autocomplete>
                          </td>
                          <td>
                            <v-autocomplete
                              v-model="editedProduct.product"
                              :items="productos"
                              label="Producto"
                              item-text="name"
                              item-value="id"
                              color="purple darken-2"
                              return-object
                            ></v-autocomplete>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:no-data>
                        Productos
                      </template>
                      <template v-slot:item.product="{ item }">
                        {{ item.product.name }}
                      </template>
                      <template v-slot:item.type_product="{ item }">
                        {{ item.type_product.text }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.pop_cause="{ item }">
            <div v-for="type in types" :key="type.value">
              <div v-if="item.pop_cause == type.value">
                {{ type.text }}
              </div>
            </div>
          </template>
          
          <template v-slot:item.accepted="{ item }">
            <div v-if="item.accepted">
              <v-btn
                class="ma-2"
                color="success"
                text
                @click="showDetailItem(item)"
              >
                <v-icon
                  dark
                  center
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                class="ma-2"
                color="red"
                text
              >
                <v-icon
                  dark
                  center
                >
                  mdi-cancel
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div v-if="showDetail">
      <RemissionDetail :showDetail="showDetail" :detailItem="editedItem" @cerrarDesdeHijo="closeModal"/>
    </div>
  </v-app>
</template>

<script>
import { ApiInv } from "./ApiInv";
import { ApiRoll  } from "../payroll/ApiRoll";
import RemissionDetail from "./RemissionDetail";
// import mensajesMixin from "../../../mixins/mensajesMixin"

export default {
  name: "Remissions",
//   mixins:[mensajesMixin],

  components:{
        RemissionDetail,
  },

  data() {
    return {
      items: [],
      itemsEmp: [],
      products:[],
      valueItem:1,
      api: new ApiInv(),
      apiEmpl: new ApiRoll(),
      model:null,
      loading: false,
      dialog:false,
      showDetail:false,
      editedIdex:-1,
      search: "",
      pages:-1,
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Fecha remisión",
          align: "start",
          sortable: true,
          value: "pop_date",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "pop_cause",
        },
        {text:'Factura',value:'bill',sortable:true},
        {text:'Empleado',value:'employee_name',sortable:true},
        {text:'Aceptada',value:'accepted' ,sortable:false}
      ],
      types:[
            {text:"VENTA", value:"VT"},
            {text:"USO", value:"US"},
            {text:"GARANTIA", value:"GR"},
            {text:"OTRO", value:"OT"},
      ],

      product_headers: [
        { text: "Item", value: "item", sortable:false },
        { text: "Cantidad", value: "quantity", sortable:false },
        { text: "Tipo de Producto", value: "type_product", sortable:false},
        { text: "Producto", value: "product", sortable:false },
        { text:'',value:'actions',sortable:false },
      ],

      typesProducts:[
        {text:"VENTA", value:"VT"},
        {text:"CONSUMO", value:"CN"}
      ],
      editedItem:{
        id:-1,
        // entry_date:"",
        pop_cause:{text:"VENTA", value:"VT"},
        bill:0,
        // accepted:true,
      },
      defaultItem:{
        id:-1,
        // entry_date:"",
        pop_cause:{text:"VENTA", value:"VT"},
        bill:0,
        // accepted:true,
      },
      editedProduct:{
        quantity:0,
        type_product:{text:"VENTA", value:"VT"},
        product:{"id":-1, value:""},
      },      
      defaultProduct:{
        quantity:0,
        type_product:{text:"VENTA", value:"VT"},
        product:{"id":-1, value:""},
      }, 
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
    };
  },
  computed:{
    formTitle(){
        return (this.editedIdex === -1 ? "Nueva": "Editar") + " Remisión"
    },
    formIsValid () {
      return (
        this.editedItem.pop_cause &&
        this.editedItem.bill &&
        this.products.length > 0
      )
    },      
  },
  watch:{
      dialog(val){
          val || this.close()
      },
  },
  methods: {
    async iniciar() {
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}`
        let r = await this.api.getEgresosP(params);
        let re = await this.apiEmpl.getEmpleados();
        this.items = r;
        this.itemsEmp = re;
        this.productos = await this.api.getProductosP(params);

      } catch (error) {
        alert(error);
        // this.mensaje(error,"Error","error")
        this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    closeModal(){
      this.showDetail = !this.showDetail;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    close(){
        this.dialog = false
        this.products = []
        this.valueItem = 1
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedProduct = Object.assign({}, this.defaultProduct);
        });
    },
    closeProduct(){
      this.$nextTick(() => {
        this.editedProduct = Object.assign({}, this.defaultProduct);
      });
    },
    showDetailItem(item){
      this.editedIdex = this.items.indexOf(item)
      this.editedItem = Object.assign({},item)
      this.showDetail=true
    },
    editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
    },
    push_product() {
          this.editedProduct["item"] = this.valueItem
          this.products.push(this.editedProduct)
          this.valueItem += 1
          this.closeProduct()
    },
    async save(){
      let cp = this.editedItem;
      let details = []
      cp["pop_cause"] = this.editedItem["pop_cause"]["value"]
      cp["enterprise"] = localStorage.getItem("SucursalId")

      for (const product in this.products){
        let detail = this.products[product]
        detail["type_product"] = detail["type_product"]["value"]
        detail["product"] = detail["product"]["id"]
        details.push(detail)
      }

      if (this.model != null){
        cp["employee"] = this.model.id
      }
      cp["details_egress"] = details
      try {
        this.loading = true
        this.formIsValid = false
        let r = await this.api.saveEgresos(cp)
        if (!r.ok){
					if ("details_egress" in r){
						this.$swal("Error",Object.values(r["details_egress"]["0"]).toString(),"error")
					}else{
						this.$swal("Error", Object.values(r)[0][0],"error")
					}
				}else{
          this.$swal("Guardado","Ingreso Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
				}
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.formIsValid = true
        this.loading = false
      }
    },
    async deleteItem(item){
      let i = this.products.indexOf(item)
      this.products.splice(i,1)
    },
  },
  created() {
    this.iniciar();
  },
};
</script>

<style scoped>
</style>