<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          :loading="loading"
          :items-per-page="pages"
          loading-text="Cargando..."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                <v-icon>cached</v-icon>
              </v-btn>

              <v-dialog v-model="dialog" max-width="100%" dense persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="2" >
                        <v-text-field v-model="editedItem.id" label="ID" disabled="" ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field 
                          v-model="editedItem.code" 
                          label="Código"
                          :rules="rules.in_req"  
                          color="purple darken-2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field 
                          v-model="editedItem.name" 
                          label="Nombre"
                          :rules="rules.in_req"
                          color="purple darken-2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="editedItem.category"
                          :items="categorias"
                          label="Categorías"
                          item-text="description"
                          item-value="id"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field 
                          v-model="editedItem.buy_price" 
                          label="Precio compra"
                          :rules="rules.in_req"
                          color="purple darken-2"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field 
                          v-model="editedItem.sale_price" 
                          label="Precio de Venta"
                          :rules="rules.in_req"
                          color="purple darken-2"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete 
                          v-model="editedItem.edit" 
                          :items="booltypes"
                          label="Editable"
                          item-text="text"
                          item-value="value"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { ApiInv } from "./ApiInv";
// import mensajesMixin from "../../../mixins/mensajesMixin"

export default {
  name: "Producto",
//   mixins:[mensajesMixin],
  data() {
    return {
      items: [],
      api: new ApiInv(),
      loading: false,
      search: "",
      pages:-1,
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Codigo",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        {text:'Categoria',value:'cat_description' ,sortable:true},
        {text:'Precio compra',value:'buy_price' ,sortable:false},
        {text:'Precio Venta',value:'sale_price',sortable:false},
        {text:'Acciones',value:'actions',sortable:false}
      ],
      dialog:false,
      editedIdex:-1,
      editedItem:{
          id:-1,
          code:"",
          name:"",
          sale_price:0,
          edit:false,
          buy_price:0,
          category:{"id":1, "description":""}
      },
      defaultItem:{
          id:-1,
          code:"",
          name:"",
          sale_price:0,
          edit:{value:false},
          buy_price:0,
          category:{"id":1, "description":""}
      },
      categorias:[],
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
    };
  },
  computed:{
      formTitle(){
          return (this.editedIdex === -1 ? "Nuevo": "Editar") + " Producto"
      },
      formIsValid () {
        return (
          this.editedItem.code &&
          this.editedItem.name &&
          this.editedItem.sale_price &&
          this.editedItem.category &&
          this.editedItem.buy_price
        )
      },      
  },
  watch:{
      dialog(val){
          val || this.close()
      }
  },
  methods: {
    async iniciar() {
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}`
        let r = await this.api.getProductosP(params);
        this.items = r;
        this.categorias = await this.api.getCategorias()     
      } catch (error) {
        alert(error);
        // this.mensaje(error,"Error","error")
        this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    close(){
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    },
    editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
    },
    async save(){
      const cp = this.editedItem;
      const cat = cp["category"];
      
      let idCat = null;
      
      if(cat["id"]!==undefined){
        idCat = cat["id"]
      }
      else{
        idCat = cat;
      }
      // console.log(cat);
      const obj = {
        "id":cp["id"],
        "enterprise": localStorage.getItem("SucursalId"),
        "code":cp["code"],
        "name":cp["name"],
        "sale_price":cp["sale_price"],
        "edit":cp["edit"]["value"],
        "buy_price":cp["buy_price"],
        "category_id": idCat,
        "category": idCat,
      }
      console.log(obj)
      try {
        this.loading = true
        this.formIsValid = false
        let r = await this.api.saveProductos(obj)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }
        else{
          this.$swal("Guardado","Producto Guardado Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.formIsValid = true
        this.loading = false
      }
    },
    async deleteItem(item){
        this.$swal({
        title: '¿Está Seguro?',
        html: `Borrar Producto <br> <b>${item.name} </b>`,
        type: 'warning',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Borrarlo!',
        cancelButtonText: 'No, Mantenerlo!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(async(result) => {
        if(result.value){
          let r = await this.api.delProductos(item.id)
          if (r.status === 500){
            this.$swal("Error","No se puede borrar el producto","error")
          }else{
            this.$swal("Borrado", "Se borro satisfactoriamente el producto", "success")
            this.iniciar()
          }
          // console.log(response)
          // this.iniciar()
        }
      })
    }
  },
  created() {
    this.iniciar();
  },
};
</script>

<style scoped>
</style>