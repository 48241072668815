<template>
  <v-container>
    <div class="text-center">
      <v-dialog 
        v-model="mostrar" 
        max-width="500"
        persistent
      >
        <v-card
        v-model="mostrar"
        >
          <v-card-title>
            <span class="text-h6 font-weight">Productos</span>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1"
              :loading="loading"
              loading-text="Cargando..."
            >
            <template v-slot:item.type_product="{ item }">
              <div v-for="type in typesProducts" :key="type.value">
                <div v-if="item.type_product == type.value">
                  {{ type.text }}
                </div>
              </div>
            </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="cerrarHijo"
            >
              Close
            </v-btn>
          </v-card-actions>          
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>

import { ApiInv } from "./ApiInv";
export default {

  name : "IngressDetail",
  props : [
    "showDetail",
    "detailItem"
  ],

  data(){
    return {
      items: [],
      id:this.detailItem.id,
      api: new ApiInv(),
      loading: false,
      search: "",
      headers: [
        {text:'Item', value: "item" },
        {text:'Cantidad',value:'quantity' ,sortable:true},
        {text:'Tipo de Producto',value:'type_product' ,sortable:false},
        {text:'Producto',align: "start",value:'product_description',sortable:false},
      ],
      typesProducts:[
        {text:"VENTA", value:"VT"},
        {text:"CONSUMO", value:"CN"}
      ],
    }
  },
  methods: {
    cerrarHijo(){
      this.$emit("cerrarDesdeHijo")
    },
    async start() {
      try {
        this.loading=true
        // console.log(this.detailItem.id)
        let r = await this.api.getIngresosDetail(this.id)
        this.items = r["details"]
      }catch (error) {
        alert(error)
      }finally{
        this.loading=false
      }
    },
  },
  computed:{
    mostrar:{
      get(){
        return this.showDetail
      },
      set(valor){
          return valor
      }
    }
  },
  created() {
    this.start();
  },
}
</script>

<style scoped>

</style>